<template>
  <div id="brandinfo">
    <Header />
    <!-- banner -->
    <div class="abtbanner" v-if="brandInfoData">
        <div class="crumbsbox">
            <div class="basecont">
                <router-link to="/">首页</router-link>
                <span>&gt;</span>
                <router-link to="/brand">B2P</router-link>
                <!-- <span>&gt;</span>
                <router-link :to="{name:'Brandlist',query:{title:xie}}">{{xie}}</router-link> -->
                <span>&gt;</span>           
                <a href="javascript:;" class="on">{{brandInfoData.name}}</a>
            </div>
        </div>
      <div class="bag">
        <!-- pc -->
        <img :src="brandInfoData.brandVideoPic" alt="">
        <!-- mobile -->
        <img :src="brandInfoData.brandVideoPic" alt="">
      </div>
      <div class="cont">
        <div class="basecont">
          <div class="font">
            <div class="tit">
              {{brandInfoData.name}}
            </div>
            <a href="javascript:;" :data-video-src="brandInfoData.brandVideoUrl" class="f_video">
              <i><img src="../../assets/images/videicon1.png" alt=""></i>
              <span>播放视频</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 品牌产品 -->
    <div class="brandinfo-sec2 base100" v-if="br2cont.length">
        <div class="basecont">
            <div class="abtbox-tit">
                <div class="tit basefont36">
                    品牌产品
                </div>
            </div>
            <div class="f_nav" v-if="br2cont.length>1">
                <swiper :options="brandinfo2_nav" class="brandinfo2_nav" ref="">
                    <swiper-slide v-for="(item,index) in br2cont" :class="{on:bracun==index}" :key="index">
                        <a href="javascript:;" @click="nav1(index)" class="item">{{item.name}}</a>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="f_cont">
                <div class="cont active">
                    <div class="item" v-for="(item,index) in prolist" :key="index">
                        <div class="fzicon" v-if="item.matchMyCar==1">
                          <img src="../../assets/images/remenimgf1.png" alt="">
                        </div>
                        <div class="img">
                            <img :src="item.spuDefaultImg" :alt="item.spuName">
                        </div>
                        <div class="font">
                            <div class="tit">
                            {{item.spuName}}
                            </div>
                            <div class="text" v-html="item.spuDesc">
                            </div>
                            <div class="jiage">
                            <span class="name">¥ {{item.spuPrice}}</span>
                            <div class="label">
                                <span v-for="(it1,in1) in item.label" :key="in1">{{it1}}</span>
                            </div>
                            </div>
                        </div>
                        <div class="f_jut">
                            <div class="f_icon">
                            <!-- <a href="javascript:;"  v-if="toglecollect" @click="shopgouwu($event,item,index)" class="li"><img src="../../assets/images/gouwucheioncimg1.png" alt=""></a> -->
                            <!-- <a href="javascript:;" @click="proaltShow()" class="li"><img src="../../assets/images/gouwucheioncimg1.png" alt=""></a> -->
                            <a href="javascript:;" v-if="toglecollect" @click="productStort(index,item.isCollect)" :class="{li:true,shou:true,on:item.isCollect == 0 || item.isCollect == null?false:true}">
                                <img src="../../assets/images/wushouchagn1.png" alt="">
                                <img src="../../assets/images/wushouchagn1-1.png" alt="">
                            </a>
                            <router-link :to="{name: 'Productlistinfo',params:{id:item.id,pan:0}}" class="li"><img src="../../assets/images/fgouwuimgf1.png" alt=""></router-link>
                            </div>
                            <!-- <div class="fz_but" @click="proaltShow()">
                              <div class="but">
                                  去APP购买
                              </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                
            </div>
            <router-link :to="{name: 'Productlist',params:{pan: 0,id: userid,title: brandInfoData.name}}" class="hm-but f_but">
                <span>查看全部</span>
                <i>
                    <img src="../../assets/images/jiantouyo1.png" >
                    <img src="../../assets/images/jiantouyo2.png" >
                </i>
            </router-link>
        </div>
    </div>
    <!-- 品牌历史 -->
    <div class="brandinfo-sec1 abtbox-sec1" v-if="brandInfoData.history">
      <div class="cont base80">
        <div class="basecont">
          <div class="abtbox-tit">
            <div class="tit basefont36">
              {{abt1data.title}}
            </div>
            <div class="text" v-html="brandInfoData.history"></div>
          </div>
          <div class="img">
            <img :src="brandInfoData.historyImage" alt="">
          </div>
        </div>
        
      </div>
    </div>
    <!-- 工艺&理念 -->
    <div class="brandinfo-sec3" v-if="brandInfoData">
        <div class="cont" v-for="(item,index) in JSON.parse(brandInfoData.introFirst)" :key="index">
            <div class="f_jut">
                <div class="basecont">
                    <div class="font">
                        <div class="tit basefont36">{{item.title}}</div>
                        <div class="text" v-html="item.content"></div>
                    </div>
                </div>
            </div>
            <div class="img">
                <img :src="item.imageUrl" :alt="item.title">
            </div>
        </div>
    </div>
    <!-- 精彩赛事 -->
    <div class="brandinfo-sec4 base100" v-if="brandInfoData.productBrandEventList.length">
        <div class="basecont">
            <div class="abtbox-tit">
                <div class="tit basefont36">
                    精彩赛事
                </div>
            </div>
            <swiper :options="brandinfo4_cont" class="brandinfo4_cont" ref="">
                <swiper-slide v-for="(item,index) in brandInfoData.productBrandEventList" :key="index">
                    <a :href="item.jumpUrl" :title="item.name" target="_blnk" rel="nofollow" class="item">
                        <div class="img">
                            <img :src="item.image" :alt="item.name">
                            <div class="label">
                                <p>{{item.monthChinese}}</p>
                                <div class="name">{{item.month}}</div>
                                <p>{{item.year}}年</p>
                            </div>
                        </div>
                        <div class="font">
                            <div class="tit">{{item.name}}</div>
                            <div class="but">
                                <span>查看更多</span>
                                <i><img src="../../assets/images/jiantouhuiseimgfa1.png" alt="箭头"></i>
                            </div>
                        </div>
                    </a>
                </swiper-slide>
            </swiper>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default{
  name: "Brandlistinfo",
  components:{
    Header,
    Footer,
    swiper,
    swiperSlide,
  },
  data(){
    return {
          // 系列图片
       bracun:0,
       brandinfo2_nav: {
        watchOverflow: true,
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        speed: 900,
      },
      brandinfo4_cont: {
        watchOverflow: true,
        observer: true,
        slidesPerView: 3,
        observeParents: true,
        speed: 900,
        spaceBetween: 50,
        breakpoints: {
            1440: {  //当屏幕宽度小于等于1280
            slidesPerView: 3,
            spaceBetween: 30
            },
            768: {  //当屏幕宽度小于等于768
            slidesPerView: 2,
            spaceBetween: 15,
            },
            567: {  //567
            slidesPerView: 1,
            spaceBetween: 10,
            }
        }
      },
      br2cont: '',
      banner:[],
      abt1data:{
          title: "品牌历史",
      },
      /* 工艺 */
      linian:[
          {
              title: "工艺&理念",
              text: `<p>
                    WELD是一家以工程性能为导向的公司，通过领先的设计和现实世界的结果来实现赛车应用。 WELD 工程师设计并制造了直径从 10&quot; 到 26&quot; 的轮子，适用于几乎所有类型的应用，包括 30,000 多个零件号。 WELD 车轮在美国设计和制造。
                </p>
                <p>
                    <br/>
                </p>
                <p>
                    Weld Racing 主导了所有级别的直线加速赛和椭圆赛道赛。 Weld Racing 的车轮可以承受高达 330 英里/小时的动力和速度，由最高级的锻造铝制成。 Weld Street &amp; Strip 售后车轮使用与 Weld&#39;s Racing 车轮相同的锻造合金，在赛道上诞生。&nbsp;
                </p>`,
              url: require('../../assets/images/gongyiimgfaw1.jpg')
          },
          {
              title: "品牌风格",
              text: "Weld标志性的售后车轮造型适合经典、肌肉车、专业巡回赛或现代肌肉车。焊接锻造售后轮辋经过精密设计，以无与伦比的质量和造型改善加速和制动。WeldOffroad& Truck 车轮是坚固、精心设计和高精度制造的锻造车轮。",
              url: require('../../assets/images/gongyiimgfaw2.jpg')
          }
      ],
      /* 精彩赛事 */
      br4cont:[
          {
              title: "袋装黑色福特野马 S550",
              url: require('../../assets/images/saishiimgfaw1.jpg'),
              year: "2019",
              month: "十二",
              day: "12"
          },
          {
              title: "增压街车接管雪佛兰CAMARO ZL1",
              url: require('../../assets/images/saishiimgfaw2.jpg'),
              year: "2019",
              month: "十二",
              day: "12"
          },
          {
              title: "火炬红克尔维特 C7 黄貂鱼准备好拖带",
              url: require('../../assets/images/saishiimgfaw3.jpg'),
              year: "2019",
              month: "十二",
              day: "12"
          }
      ],
      xie:"",
      brandInfoData: '', //详情数据
      prolist:{},
      categoryId: '',
      page: 1,
      size: 3,
      brandId: '',
      toglecollect: false,
      userid: 0,
    }
  },
  created(){
    this.xie=this.$route.params.title;
    this.userid = this.$route.params.userid;
  },
  mounted(){
     $(function(){
       // 视频弹窗
        function HiVideoPop(obj) {
            var oVideoBox = $("#hi-video-pop"); //弹窗
            var oClose = oVideoBox.find(".hi-close"); //关闭按钮
            var oVideo = oVideoBox.find("video"); //视频标签
            // 点击显示弹窗，设置视频src，播放视频
            $(document).on('click',obj,function(){
                oVideoBox.stop().fadeIn();
                oVideo.attr("src", $(this).attr("data-video-src"));
                oVideo.trigger("play");
            });
            // 点击关闭弹窗，暂停视频播放
            oClose.click(function() {
                oVideoBox.stop().fadeOut();
                oVideo.trigger("pause");
            });
            
        }
        // 调用
        HiVideoPop('.f_video'); //参数为点击的对象，必须带有data-video-src属性
     });
    //获取详情数据
    this.getBrandInfo();
    if(this.$store.state.loginStore.login){
      this.toglecollect=true;
    }else{
      this.toglecollect=false;
    }
  },
  beforeUpdate(){
    
  },
  methods:{
    proaltShow(){
      $(".proinfoAlt").fadeIn();
    },
    nav1:function(index){
          this.bracun=index;
          this.categoryId = this.br2cont.id;
          this.$nextTick( () => {
            this.getProductList();
          });
    },
    productStort(index,isCollect){  //收藏
      if(isCollect==null || isCollect==1){
        isCollect=0;
      }else{
        isCollect=1;
      }
      let url;
      if(this.$store.state.loginStore.login){
        /* 登录的接口 */
        url = "/api2/portal/auth/product/spuInfo/collectSpu";
      }else{
        /* 没有登录的接口 */
        url = "/api2/portal/product/spuInfo/collectSpu";
      }
      this.$axios.post(url,{
        spuId: this.prolist[index].id,
        type: isCollect
      }).then(res => {
        if(res.code=="000000"){
          if(this.prolist[index].isCollect == 0 || this.prolist[index].isCollect == null){
            this.prolist[index].isCollect=1;
            this.$message({
                message: '收藏成功!',
                type: 'success',
            });
          }else {
            this.prolist[index].isCollect=0;
            this.$message({
                message: '取消收藏!',
                type: 'success',
            });
          }
          
        }
      }).catch(error => {
        console.log(error);
      });
      // this.prolist[index].shou=!this.prolist[index].shou;
    },
    shopgouwu:function(){ //添加购物车
        this.$message('添加成功');
    },
    // 获取详情数据
    getBrandInfo(){
        var id = this.$route.params.userid;
        this.$axios.post('/api2/portal/product/brand/getByIdForPC',{id}).then(res => {
            if(res.code == "000000"){
              console.log(res);
                this.brandInfoData = res.data;
                document.title = res.data.name;
                this.brandId = res.data.id;
                this.$nextTick( () => {
                    this.getProNav(); //获取品牌产品分类导航数据
                });
            }else{
                document.title = "B2P";
            }
        }).catch(error => {
            console.log(error);
        });
    },
    getProNav(){  //获取品牌产品分类数据
        this.$axios.post('/api2/portal/product/category/findByBrandId',{
            brandId: this.brandId,
        }).then( res =>{
            // console.log(res);
            if(res.code=="000000"){
                this.br2cont = res.data;
                if(res.data.length){
                  this.categoryId = res.data[0].id;
                  this.$nextTick( () => {
                      this.getProductList(); //获取品牌产品数据
                  });
                }
                
            }
        }).catch( error => {
            console.log(error);
        } );
    },
    getProductList(){  //获取品牌产品数据
      let url;
      if(this.$store.state.loginStore.login){
        /* 登录的接口 */
        url = "/api2/portal/auth/product/spuInfo/findByCondition";
      }else{
        /* 没有登录的接口 */
        url = "/api2/portal/product/spuInfo/findByCondition";
      }
      this.$axios.post(url,{
        categoryId: this.categoryId,
        brandId: this.brandId,
        current: this.page,
        size: this.size,
      }).then(res => {
        // console.log(res);
        if(res.data){
          this.prolist = res.data.data;
        }else{
          this.$message({
                message: '没有产品',
                type: 'error',
            });
        }
        
      }).catch(error => {
        console.log(error);
      });
    },
  }
}

</script>

